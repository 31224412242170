import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import Subscribe from '../components/subscribe/Subscribe';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>What is behind the magic at Conjurer?</PostFullTitle>
          </PostFullHeader>
          <PostFullContent className="post-full-content">
            <div className="post-content">
              <h2>Our vision</h2>
              <p>We strive for a world where people are no longer treated as cogs in a machine and instead become part of a thriving ecosystem</p>
              <h2>Our mission</h2>
              <p>To make workplaces more humane and bring joy into people’s lives at work</p>
              <h2>Our purpose</h2>
              <p>To help revolutionize organizational dynamics by advocating for and implementing humane, effective work practices that prioritize the well-being and growth of individuals.</p>
              <h2>Our values</h2>
              <ul>
                <li>People first</li>
                <li>Empowerment and autonomy</li>
                <li>Humility and respect</li>
              </ul>
              <h2>How do we help our customers?</h2>
              <p>We provide:</p>
              <ul>
                <li>Education on the fundamental principles of organization dynamics required to create a truly adaptive organization</li>
                <li>Transformative group workshops and learning experiences</li>
                <li>Tailored strategies for organization evolution</li>
              </ul>
            </div>
          </PostFullContent>
        </article>
        {/* The big email subscribe modal content */}
        {config.showSubscribe && <Subscribe title="Do we sound like the kind of organization you would like to work with?" />}
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
